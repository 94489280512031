import { template as template_b540f224e38a46d093b88dd66a208279 } from "@ember/template-compiler";
const WelcomeHeader = template_b540f224e38a46d093b88dd66a208279(`
  <div class="login-welcome-header" ...attributes>
    <h1 class="login-title">{{@header}}</h1>
    {{yield}}
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default WelcomeHeader;
