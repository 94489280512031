import { template as template_6e80f5f0e931468da6c8f2f4220117ca } from "@ember/template-compiler";
import SortableColumn from "./sortable-column";
const ActivityCell = template_6e80f5f0e931468da6c8f2f4220117ca(`
  <SortableColumn
    @sortable={{@sortable}}
    @number="true"
    @order="activity"
    @activeOrder={{@activeOrder}}
    @changeSort={{@changeSort}}
    @ascending={{@ascending}}
    @name="activity"
  />
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default ActivityCell;
